import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import { Typography } from '@mui/material';
import { FormProvider, RHFTextField } from '../../shared/hook-form';
import { useResetPassword } from '#/api/userQueries';
import useLocales from '#/hooks/useLocales';

type FormValuesProps = {
  email: string;
};

export default function AuthResetPasswordForm() {
  const { translate } = useLocales();

  const fromPartner = localStorage.getItem('gg_partner_key');

  const { mutateAsync: resetPassword } = useResetPassword(fromPartner);

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .email('Email must be a valid email address'),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: { email: '' },
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
    setValue,
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await resetPassword(data).then(() => {
        toast.success(
          String(translate('toast_notifications.success.password_reset'))
        );
        setValue('email', '');
      });
    } catch (error) {
      toast.error(
        String(translate('toast_notifications.error.password_reset'))
      );
    }
  };

  const isDisabled = !isDirty || isSubmitting;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <RHFTextField
        name="email"
        label={String(translate('global.formLabels.emailAddress'))}
      />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{ mt: 3 }}
        disabled={isDisabled}
      >
        <Typography>
          {String(translate('global.loginInfo.sendRequest'))}
        </Typography>
      </LoadingButton>
    </FormProvider>
  );
}
